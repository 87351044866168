<template>
  <div id="print">
    <div>
      <!-- <p class="text-center title">上海奥吉实业有限公司</p> -->
      <p class="text-center sub-title">过磅单</p>
      <ul v-for="item in data" :key="item.id" class="ticket-item">
        <a-row :gutter="24">
          <a-col :span="10" style="text-align: right;">
            车牌号：
          </a-col>
          <a-col :span="12" style="text-align: left;">
            {{ item.plate_no }}
          </a-col>
          <a-col :span="10" style="text-align: right;">
            类型：
          </a-col>
          <a-col :span="12" style="text-align: left;">
            {{ item.weigh_type === 'weight' ? '重' : '空' }}
          </a-col>
          <a-col :span="10" style="text-align: right;">
            过磅重量：
          </a-col>
          <a-col :span="12" style="text-align: left;">
            {{ item.weight }}kg
          </a-col>
          <a-col :span="10" style="text-align: right;">
            日期：
          </a-col>
          <a-col :span="12" style="text-align: left;">
            {{ item.weigh_date }}
          </a-col>
        </a-row>
        <hr/>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    }
  }
  // ,
  // created() {
  //   this.initData()
  // },
  // data() {
  //   return {
  //     currentData: {}
  //   }
  // },
  // initData() {
  //   if (this.data) {
  //     this.currentData = this.data
  //   }
  // }
}
</script>

<style scoped>
@page{
  size: auto;
  margin: 10mm;
}

html{
  background-color: #FFFFFF;
  margin: 0;
}

@media print {
  html, body {
    height: inherit;
  }
}

#print {
  min-width: 300px;
  padding: 20px;
}

#print p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li:last-child {
  margin-top: 20px;
}

span {
  color: black;
  font-size: 18px;
}

.ticket-item {
  margin-bottom: 20px;
  text-align: center;
  color: black;
  font-size: 18px;
}

.text-center {
  text-align: center;
}
.title {
  font-size: 25px;
  font-weight: 600;
  color: black;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center img{
  width: 150px;
  height: 150px;
}
</style>
